import contactImg from '../../../src/images/contact.gif';


export const homeObjOne = {
   
    primary:true,
     lightBg:false, 
     imgStart:'', 
     lightTopLine:true, 
     lightTextDesc:true, 
     buttonLabel:'Get Started', 
    
     description2: 'HeadOffice: 37 Abule-nla by Ademuyiwa, E/B (West),  Lagos  ',
     description3: 'Branch Office: 62, Ibadan street by Abule-nla junction, E/B (West),  Lagos ', 
     
    
     headline:'  Phone: 08034493977, 08023004437', 
     lightText: true,
     topLine: 'Our contact',
     img: contactImg,
     alt:'Image',
     start:''
}; 

