import FourImg from '../../../src/images/experiential-marketing3.jpeg';




export const homeObjOne = {
    // lightBg:true,
    primary:true,
     lightBg:false, 
     imgStart:'', 
     lightTopLine:true, 
     lightTextDesc:true, 
     buttonLabel:'Get Started', 
     description: 'Larrykay events is a customer  management  and Engagement  firm providing services in customer engagement dynamics, brand support , channel management, Activation/Event, Field Marketing and Content Management',
     headline:' Customer management & engagement firm', 
     lightText: true,
     topLine: '',
     img: FourImg,
    //  img: require('../../images/svg3.svg'),
  
     alt:'Image',
     start:''
}; 

