import FourImg from '../../../src/images/brand-awareness2.png';

export const homeObjOne = {
    // lightBg:true,
    primary:true,
     lightBg:false, 
     imgStart:'', 
     lightTopLine:true, 
     lightTextDesc:true, 
     buttonLabel:'Get Started', 
     description: "Larrykay events is a customer  management  and Engagement  firm providing services in customer engagement dynamics, brand support , channel management, Activation/Event, Field Marketing and Content Management ", 
     description2: "We are experts at leveraging consumer passions to deliver key business benefits to our clients ",
     description3: "Our proposition is based on a deep understanding of the drivers of consumer passion and the creation of unusual solutions  - especially brand affinity,  art & culture, cause related activities or user generated content.",
     headline:' What We Stand For', 
     lightText: true,
     topLine: 'About Us',
     img: FourImg,
     // img: require('../../images/svg3.svg'),
     alt:'Image',
     start:''
}; 




