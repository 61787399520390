import FourImg from '../../../src/images/White1.jpg';

export const homeObjOne = {
    // lightBg:true,
    primary:true,
     lightBg:false, 
     imgStart:'', 
     lightTopLine:true, 
     lightTextDesc:true, 
     buttonLabel:'Get Started', 
     description: "The Mainland WHITE CARNIVAL,also known as (The Festival of Peace) is a yearly event,introduced in the year 2005,by LARRY KAY EVENTS.  ", 
     description2: "Residents come together immersed in great excitment with multiple activites for all and sundry The conventional appearance of resident in white apparel or a touch of white on these days is regarded as a symbol of peaceful coexistence within the community at these period.",
     description3: "The White carnival event is not your conventional event,it accommodates not only the kids,teens and the youth,but the youthful in heart,the elderly who are also big benneficiaries. A day out for the elderly to get then engaged in several indoor games such as (AYO,LUDO, DRAFT,CHESS ,CARDS ETC) It’s always fun when you see the old ones smiling again and happy in the midst of their children,grand children,their peer group and extended families at large within the Lagos Mainland community.The elderly event date comes to a close with dance as they reminisce to the ever green old music of there own time. Selected days and night-out within the event programs for musical concert,talent exhibition,creativity show case & youth experience zone.",
     headline:' White Carnival', 
     lightText: true,
     topLine: 'Our Events',
     img: FourImg,
     
     // img: require('../../images/svg3.svg'),
     alt:'Image',
     start:''
}; 




